import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const step1 = require('../assets/images/android_update1.png')
const step2 = require('../assets/images/android_install4.png')

export default () => {
  const { t } = useTranslation()
  return (
    <div className="inner">
      <header className="major">
        <h2>{t('android.update.step1')}</h2>
      </header>
      <a
        href="https://github.com/a11yArmy/Levo-Releases/releases/download/1.0/Levo.apk"
        download
      >
        {t('android.update.step1a')}
      </a>
      <header className="major" style={{ marginTop: 20 }}>
        <h2>{t('android.update.step2')}</h2>
      </header>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img
          src={step1}
          style={{
            borderWidth: 5,
            borderColor: 'black',
          }}
        />
      </div>
      <header className="major" style={{ marginTop: 20 }}>
        <h2>{t('android.update.step3')}</h2>
      </header>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img
          src={step2}
          style={{
            width: 300,
          }}
          alt={t('android.install.step4img')}
        />
      </div>
    </div>
  )
}
