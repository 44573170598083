import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import AndroidInstall from '../components/AndroidInstall'
import AndroidUpdate from '../components/AndroidUpdate'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { navigate } from 'gatsby'

const Landing = props => {
  const [activeButton, setActiveButton] = useState('install')
  const { t } = useTranslation()

  useEffect(() => {
    navigate('/')
  }, [])

  useEffect(() => {
    const searchProps = props.location.search
    const regex = /default=(.*?)(?:&|$)/g
    const defaultProp = regex.exec(searchProps)
    if (defaultProp !== null) {
      setActiveButton(defaultProp[1])
    }
  }, [props.location.search])

  return (
    <Layout>
      <Helmet>
        <title>{t('androidSetup')}</title>
        <meta name="description" content={t('androidSetup')} />
      </Helmet>

      <BannerLanding title={t('androidSetup')} subText={t('stepByStep')} />

      <div id="main">
        <section id="one">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <button
              style={{
                borderColor: 'white',
                backgroundColor:
                  activeButton === 'install' ? '#21939c' : 'transparent',
              }}
              onClick={() => {
                setActiveButton('install')
              }}
            >
              {t('install')}
            </button>
            <button
              style={{
                borderColor: 'white',
                backgroundColor:
                  activeButton === 'update' ? '#21939c' : 'transparent',
              }}
              onClick={() => {
                setActiveButton('update')
              }}
            >
              {t('update')}
            </button>
          </div>
        </section>
        <section
          id="two"
          style={{ display: activeButton === 'install' ? '' : 'none' }}
        >
          <AndroidInstall />
        </section>
        <section
          id="three"
          style={{ display: activeButton === 'update' ? '' : 'none' }}
        >
          <AndroidUpdate />
        </section>
      </div>
    </Layout>
  )
}

export default Landing
